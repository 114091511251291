import React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../components/title";

const AboutPage = () => {
  return (
    <Layout pageTitle="Über mich">
      <StaticImage className="w-full" src="../images/about.jpg" alt="contact" />
      <Title title="Daniela Gisler" />
      <div className="flex flex-col md:block m-5 max-w-4xl mx-auto">
        <div className="md:float-right md:ml-3 md:mb-3 md:mt-0 mx-auto my-5 p-5">
          <StaticImage
            className="w-60 h-60"
            src="../images/portrait.jpg"
            alt="portrait"
          />
        </div>
        <div className="p-5 flex flex-col space-y-3 md:block">
          <p className="font-bold text-lg">Ich</p>
          <Line text="habe Sozialarbeit studiert." />
          <Line text="habe eine Coaching-Ausbildung abgeschlossen (CAS)." />
          <Line text="bin befähigt Outdoor-Trainings zu leiten (Trainer Kreativ-Training)." />
          <Line text="engagiere mich in Vereinen." />
          <Line text="habe grosse Beratungserfahrung aus meiner Tätigkeit auf Sozialdiensten." />
          <Line text="mag Menschen und begegne ihnen empathisch und authentisch." />
          <Line text="habe Humor, der mich in meiner Tätigkeit als Sozialarbeiterin und als Begleiterin von Menschen unterstützen kann." />
          <Line text="bin mir der Einzigartigkeit jedes Menschen bewusst, achte sie mit ihren Bedürfnissen und Sehnsüchten." />
          <Line text="bin überzeugt, dass Menschen viele Antworten in sich tragen, die gefunden werden möchten." />
          <Line text="begegne dem Leben und der Natur mit Respekt." />
          <Line text="bin Mutter von vier erwachsenen Töchtern." />
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;

const Line = ({ text }) => {
  return (
    <div className="flex space-x-2">
      <p>...</p>
      <p>{text}</p>
    </div>
  );
};
